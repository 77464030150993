






import Button from '@/components/button.vue';
import FormInput from '@/components/form-input.vue';
import {Component, Prop, Watch} from '@/decorators';
import {parseIban} from '@/utils/iban';
import Vue from 'vue';

@Component({
    components: {FormInput, Button}
})
export default class IbanInput extends Vue {
    @Prop
    private value: string;

    @Prop
    private required: boolean;

    private iban: string = '';

    @Watch('iban')
    private onValueChanged() {
        this.value = parseIban(this.iban);
        this.$emit('input', this.value);
    }
}
