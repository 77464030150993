import {FormDataType} from '@/model/frontend/form-input';
import {parseIban} from '@/utils/iban';

const emailRegex =
    /* tslint:disable */
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* tslint:enable */

const zipRegex = /^\d{4,5}$/;
const accountNumberRegex = /^\d{10}$/;
const bicRegex = /^\d{8}$/;

function validateName(name: string): boolean {
    return name.length >= 3;
}

function validateStreet(street: string): boolean {
    return street.length >= 3;
}

function validateStreetNumber(streetNumber: string): boolean {
    return streetNumber.length <= 5 && isDigit(streetNumber.charCodeAt(0));
}

function validateAreaCode(areaCode: string): boolean | string {
    if (areaCode.length < 3) {
        return 'Bitte Vorwahl eingeben';
    }

    return areaCode[0] === '0';
}

function validatePhone(phone: string): boolean | string {
    if (phone.length < 4) {
        return 'Bitte Telefonnummer eingeben';
    }

    return true;
}

function validateEmail(email: string): boolean {
    return emailRegex.test(email);
}

function validateCity(city: string): boolean {
    return city.length >= 3;
}

function validateBirthday(birthday: string): boolean {
    let date = parseDate(birthday);
    let now = new Date();
    let before18Years = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
    return date.getTime() < before18Years.getTime();
}

function validateIban(iban: string): boolean {
    return parseIban(iban) !== null;
}

function parseDate(date: string): Date {
    let [year, month, day] = date.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

function createRegexValidator(regex: RegExp): (date: string) => boolean {
    return date => regex.test(date);
}

function isDigit(char: number): boolean {
    return char >= 0x30 && char <= 0x39;
}

export const validators: { [key: string]: (data: string) => boolean | string } = {};

validators[FormDataType.SALUTATION] = () => true;
validators[FormDataType.NAME] = validateName;
validators[FormDataType.STREET] = validateStreet;
validators[FormDataType.STREET_NUMBER] = validateStreetNumber;
validators[FormDataType.ZIP_CODE] = createRegexValidator(zipRegex);
validators[FormDataType.PHONE] = validatePhone;
validators[FormDataType.EMAIL] = validateEmail;
validators[FormDataType.CITY] = validateCity;
validators[FormDataType.COUNTRY] = (country: string) => !!country;
validators[FormDataType.RESIDENCE] = (country: string) => !!country;
validators[FormDataType.IBAN] = validateIban;
validators[FormDataType.ACCOUNT_NUMBER] = createRegexValidator(accountNumberRegex);
validators[FormDataType.BIC] = createRegexValidator(bicRegex);
validators[FormDataType.READ_MEDIUM] = () => true;

export const validatorMessages: { [key: string]: string } = {};

validatorMessages[FormDataType.NAME] = 'Ihr Name?';
validatorMessages[FormDataType.STREET] = 'Wohin sollen wir ihr Geschenk schicken?';
validatorMessages[FormDataType.STREET_NUMBER] = 'Ihre Hausnummer?';
validatorMessages[FormDataType.ZIP_CODE] = 'Postleitzahl?';
validatorMessages[FormDataType.PHONE] = 'Ihre Telefonnummer?';
validatorMessages[FormDataType.EMAIL] = 'Ihre E-Mail?';
validatorMessages[FormDataType.CITY] = 'Wohin sollen wir ihr Geschenk schicken?';
validatorMessages[FormDataType.IBAN] = 'Bitte überprüfen Sie ihre Bankdaten!';
validatorMessages[FormDataType.ACCOUNT_NUMBER] = 'Bitte überprüfen Sie ihre Bankdaten!';
validatorMessages[FormDataType.BIC] = 'Bitte überprüfen Sie ihre Bankdaten!';
