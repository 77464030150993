










import {Component, Prop, Watch} from '@/decorators';
import {RadioOption} from '@/model/frontend/radio-input';
import Vue from 'vue';

@Component({})
export default class RadioInput extends Vue {
    @Prop
    private options: RadioOption[];

    @Prop
    private value: string;

    @Prop
    private stacked: boolean;

    @Prop
    private disabled: boolean;

    private model: string = '';

    private id: string = Math.floor(Math.random() * 1e16).toString(36);

    private ids: string[] = [];

    public beforeMount(): void {
        this.ids = this.options.map(() => Math.floor(Math.random() * 1e16).toString(36));
        this.model = this.value;
    }

    @Watch('value')
    private onValueChanged() {
        this.model = this.value;
    }

    @Watch('model')
    private onModelChanged() {
        this.$emit('input', this.model);
    }

    private select(value: string) {
        if (this.disabled) {
            return;
        }
        this.model = value;
    }
}
