
















    import {Component, Prop, Watch} from '@/decorators';
    import Vue from 'vue';

    @Component({})
export default class DateInput extends Vue {
    @Prop({required: false, default: ''})
    private value: string;

    @Prop
    private disabled: boolean;

    private day: number = 0;
    private month: number = 0;
    private year: number = 0;

    private nullInBuffer: boolean = false;
    private removeCurrent: boolean = false;

    private selected: DateField | 'none' = 'none';

    public mounted(): void {
        window.document.addEventListener('keydown', this.onKeyPress);
        window.document.addEventListener('click', this.onMousePress);
        this.onValueChanged();
    }

    public beforeDestroy(): void {
        window.document.removeEventListener('keydown', this.onKeyPress);
        window.document.removeEventListener('click', this.onMousePress);
    }

    private select(selected: DateField): void {
        if (this.disabled) {
            return;
        }
        if (this.selected === 'none') {
            this.$emit('focus');
        }
        this.selected = selected;
        this.nullInBuffer = false;
        this.removeCurrent = true;
    }

    private onKeyPress(event: KeyboardEvent): void {
        if (this.selected === 'none') {
            return;
        }
        event.preventDefault();
        if (event.key === 'Backspace') {
            this[this.selected] = 0;
        } else if (event.key === 'ArrowLeft') {
            switch (this.selected) {
                case 'month':
                    this.selected = 'day';
                    break;
                case 'year':
                    this.selected = 'month';
                    break;
            }
        } else if (event.key === 'ArrowRight' || event.key === 'Tab') {
            switch (this.selected) {
                case 'day':
                    this.selected = 'month';
                    break;
                case 'month':
                    this.selected = 'year';
                    break;
            }
        } else if (event.key.charCodeAt(0) >= 0x30 && event.key.charCodeAt(0) <= 0x39) {
            let digit = event.key.charCodeAt(0) - 0x30;
            this.processDigitInput(digit);
        }
    }

    private onMousePress(event: MouseEvent): void {
        if (this.selected === 'none') {
            return;
        }
        let current = event.target as HTMLElement | null;
        while (current) {
            if (current === this.$el) {
                return;
            }
            current = current.parentElement;
        }
        this.selected = 'none';
        this.$emit('blur');
        this.onValueChanged();
    }

    @Watch('value')
    private onValueChanged(): void {
        let date = this.value.toDate();
        if (!date) {
            this.day = 0;
            this.month = 0;
            this.year = 0;
        } else {
            this.day = date.getDate();
            this.month = date.getMonth() + 1;
            this.year = date.getFullYear();
        }
    }

    @Watch('day')
    @Watch('month')
    @Watch('year')
    private emitInputEvent(): void {
        if (this.day === 0 && this.month === 0 && this.year === 0) {
            this.$emit('input', '');
        } else if (this.day !== 0 && this.month !== 0 && this.year !== 0) {
            if (this.year < 1000) {
                return;
            }
            this.$emit('input', `${this.year.toPaddedString(4)}-${this.month.toPaddedString(2)}-${this.day.toPaddedString(2)}`);
        }
    }

    private processDigitInput(digit: number) {
        if (this.selected === 'none') {
            return;
        }
        let current = this[this.selected];
        let goNextMin = this.getGoNextMin(this.selected);
        if ((current === 0 || current >= goNextMin || this.removeCurrent) && digit === 0) {
            this.nullInBuffer = true;
            this[this.selected] = 0;
            return;
        }
        let newValue: number;
        if (this.removeCurrent) {
            newValue = digit;
            this.removeCurrent = false;
        } else {
            newValue = current * 10 + digit;
        }
        let maxValue = this.getMax(this.selected);
        if (newValue > maxValue) {
            this[this.selected] = digit;
        } else {
            this[this.selected] = newValue;
            if (newValue >= goNextMin || (this.nullInBuffer && this.selected !== 'year')) {
                this.selected = this.getNext(this.selected);
                if (this.selected === 'none') {
                    this.$emit('blur');
                }
                this.removeCurrent = true;
            }
        }
        this.nullInBuffer = false;
    }

    private get dayString(): string {
        if (this.day === 0) {
            if (this.nullInBuffer && this.selected === 'day') {
                return '00';
            }
            return 'tt';
        }
        return this.day.toPaddedString(2);
    }

    private get monthString(): string {
        if (this.month === 0) {
            if (this.nullInBuffer && this.selected === 'month') {
                return '00';
            }
            return 'mm';
        }
        return this.month.toPaddedString(2);
    }

    private get yearString(): string {
        if (this.year === 0) {
            return 'jjjj';
        }
        return this.year.toPaddedString(4);
    }

    private getMax(field: DateField): number {
        switch (field) {
            case 'day':
                return 31;
            case 'month':
                return 12;
            case 'year':
                return new Date().getFullYear();
        }
    }

    private getGoNextMin(field: DateField): number {
        switch (field) {
            case 'day':
                return 10;
            case 'month':
                return 3;
            case 'year':
                return 1000;
        }
    }

    private getNext(field: DateField): DateField | 'none' {
        switch (field) {
            case 'day':
                return 'month';
            case 'month':
                return 'year';
            case 'year':
                return 'none';
        }
    }
}

type DateField = 'day' | 'month' | 'year';
