







































































import DateInput from '@/components/date-input.vue';
import RadioInput from '@/components/radio-input.vue';
import ReadMore from '@/components/read-more.vue';
import {Component, Prop, Watch} from '@/decorators';
import {PortalSettings, PublicSubscriptionPortalOffer} from '@/model/backend/campaign-api';
import {FormDataType} from '@/model/frontend/form-input';
import {RadioOption} from '@/model/frontend/radio-input';
import {scrollAnimated} from '@/utils/scroll';
import {validatorMessages, validators} from '@/utils/validator';
import Vue from 'vue';
import {Getter} from 'vuex-class';

@Component({
    components: {DateInput, ReadMore, RadioInput}
})
export default class FormInput extends Vue {
    @Prop([String])
    private dataType: FormDataType;

    @Prop
    private required: boolean;

    @Prop
    private value: string;

    @Prop
    private disabled: boolean;

    @Prop
    private placeholder: string;

    @Prop
    private fixedPlaceholder: boolean;

    @Prop({required: false, default: ''})
    private description: string;

    @Prop({required: false, default: () => ({})})
    private customProperties: { [index: string]: string };

    @Prop({required: false, default: 0})
    private cutFrom: number;

    @Prop({required: false})
    private autocomplete: string;

    @Getter
    private offer: PublicSubscriptionPortalOffer;

    @Getter
    private settings: PortalSettings;

    private id: string = Math.floor(Math.random() * 1e16).toString(36);

    private mobile = ('ontouchstart' in document.documentElement);

    private model: string | boolean | Date = false;

    private oldModel: string = '';

    private state: ValidationState = 'none';

    private errorMessage: string | null = null;

    private showError: boolean = false;

    private focused: boolean = false;

    public created() {
        if (this.dataType === FormDataType.CHECKBOX) {
            this.model = false;
        } else {
            this.model = '';
        }
    }

    public mounted() {
        if (this.dataType === FormDataType.CHECKBOX) {
            this.model = this.value === 'true';

            if (this.customProperties.checkShortText) {
                this.changeShortTextDom();
            }
        } else {
            this.model = this.value;
        }
        switch (this.dataType) {
            case FormDataType.DELIVERY_DATE:
                if (this.offer.form.defaultDeliveryDate !== -1) {
                    let now = new Date();
                    let defaultValue = new Date(now.getFullYear(), now.getMonth(), now.getDate() + this.offer.form.defaultDeliveryDate);
                    this.$emit('input', defaultValue.toDateString());
                }
                break;
            case FormDataType.READ_MEDIUM:
                this.$emit('input', this.customProperties.defaultValue);
                break;
        }
        window.document.addEventListener('click', this.clickedSomewhere);
    }

    public beforeDestroy() {
        window.document.removeEventListener('click', this.clickedSomewhere);
    }

    public checkValidation(focusIfInvalid?: boolean, mustGiven?: boolean): boolean {
        if (this.disabled) {
            return true;
        }
        this.validate(mustGiven);

        if (this.state === 'valid' || (this.state === 'none' && !this.required)) {
            return true;
        }

        if (focusIfInvalid !== false) {
            this.scrollToAndFocusField();
        }

        return false;
    }

    private changeShortTextDom() {
        this.$nextTick(() => {
            // add _blank to all links
            for (let item of (this.$refs.checkShortText as HTMLDivElement).getElementsByTagName('u')) {
                item.onclick = () => {
                    this.$modal.show('dialog', {
                        text: this.description,
                        buttons: [
                            {
                                title: 'Schließen'
                            }
                        ]
                    });
                    return false;
                };
            }
        });
    }

    private clickedSomewhere(event: Event) {
        if (event.target === this.$refs.validationIcon) {
            return;
        }
        this.showError = false;
    }

    private clickedValidationIcon() {
        if (this.state !== 'invalid') {
            return;
        }
        this.showError = !this.showError;
    }

    private scrollToAndFocusField() {
        scrollAnimated(this.$el, 250, false, this.focusField);
    }

    private focusField() {
        let input = this.$refs.input;
        if (input) {
            if (input instanceof HTMLElement) {
                input.focus();
            }
        }
    }

    @Watch('value')
    private onValueChange() {
        if (this.dataType === FormDataType.CHECKBOX) {
            this.model = this.value === 'true';
        } else {
            this.model = this.value;
            this.oldModel = this.value;
        }

        if (this.dataType === 'readMedium') {
            let confirm_mandatory = false;
            if (this.value === 'DIGITAL') {
                confirm_mandatory = true;
            }
            this.$emit('setConfirmMandatory', confirm_mandatory);
        }
    }

    @Watch('model')
    private onModelChange() {
        let model = this.model;
        if (typeof model === 'object') {
            model = model.toDateString();
        }
        if (this.dataType === FormDataType.CHECKBOX) {
            model = model ? 'true' : 'false';
            this.validate();
        }
        this.$emit('input', model);
    }

    private onKeypress(event: KeyboardEvent) {
        let charCode = event.key.charCodeAt(0);
        switch (this.dataType) {
            case FormDataType.PHONE:
            case FormDataType.BIC:
            case FormDataType.ACCOUNT_NUMBER:
            case FormDataType.ZIP_CODE:
                if (!isDigit(charCode)) {
                    event.preventDefault();
                }
                break;
            case FormDataType.IBAN:
                if (isDigit(charCode) || isUpper(charCode)) {
                    return;
                }
                if (isLower(charCode)) {
                    this.$nextTick(() => this.model = (this.model as string).toUpperCase());
                    return;
                }
                event.preventDefault();
                break;
        }
    }

    private onInput(event: InputEvent) {
        let newValue = this.model as string;
        if (event.inputType === 'insertFromPaste') {
            switch (this.dataType) {
                case FormDataType.PHONE:
                case FormDataType.BIC:
                case FormDataType.ACCOUNT_NUMBER:
                case FormDataType.ZIP_CODE:
                    for (let i = 0; i < newValue.length; i++) {
                        if (!isDigit(newValue.charCodeAt(i))) {
                            this.model = this.oldModel;
                            event.srcElement.value = this.oldModel;
                            return;
                        }
                    }
                    break;
                case FormDataType.IBAN:
                    for (let i = 0; i < newValue.length; i++) {
                        let charCode = newValue.charCodeAt(i);
                        if (!isDigit(charCode) && !isLower(charCode) && !isUpper(charCode)) {
                            this.model = this.oldModel;
                            event.srcElement.value = this.oldModel;
                            return;
                        }
                    }
                    this.model = newValue = newValue.toUpperCase();
                    break;
            }
        }
        this.oldModel = newValue;
        this.validate();
    }

    private onFocus() {
        this.focused = true;
    }

    private onBlur() {
        this.focused = false;
        this.validate();
    }

    private validate(mustGiven?: boolean) {
        if (this.disabled) {
            this.errorMessage = null;
            this.state = 'none';
            return;
        }

        if (this.dataType === FormDataType.CHECKBOX) {
            this.validateCheckbox(mustGiven || false);
            return;
        }

        if (this.dataType === FormDataType.BIRTHDAY || this.dataType === FormDataType.DELIVERY_DATE) {
            let date = (this.model as string).toDate();
            if (!date) {
                if (mustGiven || this.required || this.model) {
                    this.errorMessage = 'Bitte geben Sie ein Datum ein.';
                    this.state = 'invalid';
                } else {
                    this.errorMessage = null;
                    this.state = 'none';
                }
                return;
            }
            let now = new Date();
            if (this.dataType === FormDataType.BIRTHDAY) {
                let before18Years = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate()).getTime();
                if (before18Years < date.getTime()) {
                    this.errorMessage = 'Sie müssen mindestens 18 Jahre alt sein!';
                    this.state = 'invalid';
                    return;
                } else {
                    this.errorMessage = null;
                    this.state = 'valid';
                    return;
                }
            } else if (this.dataType === FormDataType.DELIVERY_DATE) {
                if (date.getTime() < now.getTime()) {
                    this.errorMessage = 'Der Liefertermin kann nicht in der Vergangenheit liegen!';
                    this.state = 'invalid';
                    return;
                }

                if (this.offer.form.earliestSelectableDeliveryDate !== -1) {
                    let earliestDeliveryDate = new Date(now.getFullYear(), now.getMonth(),
                        now.getDate() + this.offer.form.earliestSelectableDeliveryDate);
                    if (date.getTime() < earliestDeliveryDate.getTime()) {
                        this.errorMessage = `Der frühste Liefertermin ist der ${earliestDeliveryDate.toReadableDateString()}!`;
                        this.state = 'invalid';
                        return;
                    } else {
                        this.errorMessage = null;
                        this.state = 'valid';
                        return;
                    }
                } else {
                    this.errorMessage = null;
                    this.state = 'valid';
                    return;
                }
            }
        }

        if (!this.model) {
            if (mustGiven || this.required) {
                this.errorMessage = 'Dieses Feld muss ausgefüllt werden!';
                this.state = 'invalid';
            } else {
                this.errorMessage = null;
                this.state = 'none';
            }
            return;
        }

        let validator = validators[this.dataType];
        if (!validator) {
            this.errorMessage = null;
            this.state = 'valid';
            return;
        }

        let result = validator(this.model as string);
        this.processValidationResult(result as string);
    }

    private validateCheckbox(mustGiven: boolean) {
        if (mustGiven || this.required) {
            if (this.model) {
                this.state = 'valid';
            } else {
                this.state = 'invalid';
            }
        } else {
            this.state = 'none';
        }
    }

    private processValidationResult(result: boolean | string) {
        if (typeof result === 'boolean') {
            if (result) {
                this.errorMessage = null;
                this.state = 'valid';
            } else {
                this.errorMessage = validatorMessages[this.dataType];
                this.state = 'invalid';
            }
        } else {
            this.errorMessage = result;
            this.state = 'invalid';
        }
    }

    private get effectiveFixedPlaceholder(): boolean {
        return this.fixedPlaceholder && (!this.settings.htmlPlaceholder || this.inputType === 'date');
    }

    private get htmlType(): string {
        switch (this.dataType) {
            case FormDataType.PHONE:
                return 'tel';
            case FormDataType.EMAIL:
                return 'email';
            case FormDataType.BIRTHDAY:
            case FormDataType.DELIVERY_DATE:
                return 'date';
            default:
                return 'text';
        }
    }

    private get minDate(): Date {
        if (this.dataType === FormDataType.BIRTHDAY) {
            let now = new Date();
            return new Date(now.getFullYear() - 90, now.getMonth(), now.getDate());
        } else if (this.dataType === FormDataType.DELIVERY_DATE) {
            let now = new Date();
            if (this.offer.form.earliestSelectableDeliveryDate !== -1) {
                return new Date(now.getFullYear(), now.getMonth(),
                    now.getDate() + this.offer.form.earliestSelectableDeliveryDate);
            } else {
                return now;
            }
        }
        return new Date();
    }

    private get maxDate(): Date {
        if (this.dataType === FormDataType.BIRTHDAY) {
            let now = new Date();
            return new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
        }
        return new Date();
    }

    private get openDate(): Date {
        let now = new Date();

        if (this.dataType === FormDataType.BIRTHDAY) {
            return new Date(now.getFullYear() - 30, now.getMonth(), now.getDate());
        } else if (this.dataType === FormDataType.DELIVERY_DATE) {
            return now;
        } else {
            return now;
        }
    }

    private get options(): RadioOption[] {
        // noinspection FallThroughInSwitchStatementJS
        switch (this.dataType) {
            case FormDataType.SALUTATION:
                return [
                    {
                        label: 'Frau',
                        value: 'Frau'
                    },
                    {
                        label: 'Herr',
                        value: 'Herr'
                    }
                ];
            case FormDataType.READ_MEDIUM:
                return [
                    {
                        label: this.customProperties.digitalText || 'Ich möchte die Zeitung digital lesen',
                        value: 'DIGITAL'
                    },
                    {
                        label: this.customProperties.printText || 'Ich möchte die Zeitung als Print-Ausgabe lesen',
                        value: 'PRINT'
                    }
                ];
            case FormDataType.CUSTOM_FIELD:
                if (this.customProperties.type === 'Radio') {
                    return this.customProperties
                        .values
                        .split('\n')
                        .map(e => ({label: e, value: e}));
                }
            default:
                return [];
        }
    }

    private get inputType(): InputType {
        // noinspection FallThroughInSwitchStatementJS
        switch (this.dataType) {
            case FormDataType.BIRTHDAY:
            case FormDataType.DELIVERY_DATE:
                return 'date';
            case FormDataType.SALUTATION:
            case FormDataType.READ_MEDIUM:
                return 'radio';
            case FormDataType.CHECKBOX:
                return 'checkbox';
            case FormDataType.COUNTRY:
            case FormDataType.RESIDENCE:
                return 'select';
            case FormDataType.CUSTOM_FIELD:
                switch (this.customProperties.type) {
                    case 'Text':
                        return 'input';
                    case 'Checkbox':
                        return 'checkbox';
                    case 'Radio':
                        return 'radio';
                    case 'Select':
                        return 'select';
                }
            default:
                return 'input';
        }
    }

    private get showValidationIndicator(): boolean {
        return !this.focused && this.dataType !== FormDataType.CHECKBOX && this.dataType !== FormDataType.READ_MEDIUM;
    }

    private get htmlPlaceholder(): string | null {
        if (this.mobile || this.settings.htmlPlaceholder) {
            return this.placeholder;
        }
        return null;
    }

    private get selectOptions(): { frequently: string[] | null; others: string[] } | null {
        // noinspection FallThroughInSwitchStatementJS
        switch (this.dataType) {
            case FormDataType.COUNTRY:
                return require('@/utils/countries');
            case FormDataType.RESIDENCE:
                return require('@/utils/residence');
            case FormDataType.CUSTOM_FIELD:
                if (this.customProperties.type === 'Select') {
                    return {frequently: null, others: this.customProperties.values.split('\n')};
                }
            default:
                return null;
        }
    }
}

function isDigit(charCode: number): boolean {
    return charCode >= 0x30 && charCode <= 0x39;
}

function isUpper(charCode: number): boolean {
    return charCode >= 0x41 && charCode <= 0x5A;
}

function isLower(charCode: number): boolean {
    return charCode >= 0x61 && charCode <= 0x7A;
}

type ValidationState = 'none' | 'valid' | 'invalid' | 'validating';
type InputType = 'date' | 'radio' | 'checkbox' | 'input' | 'select';
