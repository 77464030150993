export enum FormDataType {
    SALUTATION = 'salutation',
    NAME = 'name',
    STREET = 'street',
    STREET_NUMBER = 'streetNumber',
    ZIP_CODE = 'zipCode',
    CITY = 'city',
    PHONE = 'phone',
    EMAIL = 'email',
    BIRTHDAY = 'birthday',
    DELIVERY_DATE = 'deliveryDate',
    COUNTRY = 'country',
    RESIDENCE = 'residence',
    IBAN = 'iban',
    ACCOUNT_NUMBER = 'accountNumber',
    BIC = 'bic',
    READ_MEDIUM = 'readMedium',
    CHECKBOX = 'checkbox',
    LABEL = 'label',
    TEXT = 'text',
    CUSTOM_FIELD = 'customField'
}
