var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-input-wrapper" }, [
    _vm.placeholder && !_vm.settings.htmlPlaceholder && !_vm.mobile
      ? _c(
          "label",
          {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.focusField($event)
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.placeholder) + "\n    ")]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "form-input",
        class: [
          _vm.state === "invalid" ? "error" : null,
          "type-" + _vm.inputType,
          _vm.disabled ? "disabled" : null,
          _vm.mobile ? "mobile" : null
        ]
      },
      [
        _vm.effectiveFixedPlaceholder && _vm.placeholder
          ? _c(
              "span",
              {
                staticClass: "fixed-placeholder",
                on: { click: _vm.focusField }
              },
              [_vm._v(_vm._s(_vm.placeholder) + ":")]
            )
          : _vm._e(),
        _vm.inputType === "date"
          ? [
              _vm.mobile
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model,
                        expression: "model"
                      }
                    ],
                    ref: "input",
                    staticClass: "form-control",
                    attrs: {
                      disabled: _vm.disabled,
                      max: _vm.maxDate,
                      min: _vm.minDate,
                      placeholder: _vm.htmlPlaceholder,
                      type: "date"
                    },
                    domProps: { value: _vm.model },
                    on: {
                      blur: _vm.onBlur,
                      focus: _vm.onFocus,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.model = $event.target.value
                      }
                    }
                  })
                : _c("DateInput", {
                    ref: "dateInput",
                    attrs: { disabled: _vm.disabled },
                    on: { focus: _vm.onFocus, blur: _vm.onBlur },
                    model: {
                      value: _vm.model,
                      callback: function($$v) {
                        _vm.model = $$v
                      },
                      expression: "model"
                    }
                  })
            ]
          : _vm.inputType === "radio"
          ? _c("radio-input", {
              attrs: {
                disabled: _vm.disabled,
                options: _vm.options,
                stacked: _vm.dataType === "readMedium"
              },
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            })
          : _vm.inputType === "checkbox"
          ? [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model,
                      expression: "model"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    disabled: _vm.disabled,
                    id: _vm.id,
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.model)
                      ? _vm._i(_vm.model, null) > -1
                      : _vm.model
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.model,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.model = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.model = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.model = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    class: { error: _vm.state === "invalid" },
                    attrs: { for: _vm.id }
                  },
                  [
                    _vm.dataType === "customField"
                      ? _c("div", [_vm._v(_vm._s(_vm.placeholder))])
                      : _vm.customProperties.checkShortText
                      ? _c("div", {
                          ref: "checkShortText",
                          staticClass: "popup-text",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.customProperties.checkShortText
                            )
                          }
                        })
                      : _c("ReadMore", {
                          attrs: {
                            cutFrom: _vm.cutFrom,
                            value: _vm.description
                          }
                        })
                  ],
                  1
                )
              ])
            ]
          : _vm.inputType === "select"
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: "input",
                staticClass: "form-control",
                class: { empty: !_vm.model },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.model = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.onInput
                  ]
                }
              },
              [
                !_vm.settings.htmlPlaceholder
                  ? _c(
                      "option",
                      {
                        attrs: {
                          disabled: "",
                          hidden: "",
                          selected: "",
                          value: ""
                        }
                      },
                      [_vm._v("Bitte auswählen...\n            ")]
                    )
                  : _c(
                      "option",
                      {
                        attrs: {
                          disabled: "",
                          hidden: "",
                          selected: "",
                          value: ""
                        }
                      },
                      [
                        _vm._v(
                          "Bitte " + _vm._s(_vm.placeholder) + " auswählen..."
                        )
                      ]
                    ),
                _vm.selectOptions.frequently
                  ? [
                      _vm._l(_vm.selectOptions.frequently, function(option) {
                        return _c("option", { domProps: { value: option } }, [
                          _vm._v(_vm._s(option))
                        ])
                      }),
                      _c("option", { attrs: { disabled: "" } }, [
                        _vm._v(
                          "------------------------------------------------------"
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._l(_vm.selectOptions.others, function(option) {
                  return _c("option", { domProps: { value: option } }, [
                    _vm._v(_vm._s(option))
                  ])
                })
              ],
              2
            )
          : _vm.inputType === "input"
          ? _vm.htmlType === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: "input",
                staticClass: "form-control input",
                attrs: {
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete,
                  placeholder: _vm.htmlPlaceholder,
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.model)
                    ? _vm._i(_vm.model, null) > -1
                    : _vm.model
                },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  keypress: _vm.onKeypress,
                  input: _vm.onInput,
                  change: function($event) {
                    var $$a = _vm.model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.model = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.model = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.model = $$c
                    }
                  }
                }
              })
            : _vm.htmlType === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: "input",
                staticClass: "form-control input",
                attrs: {
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete,
                  placeholder: _vm.htmlPlaceholder,
                  type: "radio"
                },
                domProps: { checked: _vm._q(_vm.model, null) },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  keypress: _vm.onKeypress,
                  input: _vm.onInput,
                  change: function($event) {
                    _vm.model = null
                  }
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: "input",
                staticClass: "form-control input",
                attrs: {
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete,
                  placeholder: _vm.htmlPlaceholder,
                  type: _vm.htmlType
                },
                domProps: { value: _vm.model },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  keypress: _vm.onKeypress,
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.model = $event.target.value
                    },
                    _vm.onInput
                  ]
                }
              })
          : _vm._e(),
        _c("div", { staticClass: "focus-border" }),
        _vm.showValidationIndicator
          ? _c("span", {
              ref: "validationIcon",
              staticClass: "validation-icon",
              class: [_vm.state],
              on: { click: _vm.clickedValidationIcon }
            })
          : _vm._e()
      ],
      2
    ),
    _vm.showValidationIndicator && _vm.errorMessage
      ? _c(
          "span",
          { staticClass: "error-message", class: { show: _vm.showError } },
          [_vm._v(_vm._s(_vm.errorMessage))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }