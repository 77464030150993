export function scrollAnimated(element: Element, offset: number, scrollAlways: boolean, callback?: () => void) {
    let scrollOffsetTop = 0;
    let scrollOffsetBottom = 0;
    if (window.document.getElementsByClassName('app-header').length && window.innerWidth <= 768) {
        scrollOffsetTop += 65;
    }
    let clientRect = element.getClientRects()[0];
    if (!scrollAlways && clientRect.top >= offset + scrollOffsetTop && (clientRect.height > window.innerHeight ||
        clientRect.top + clientRect.height + offset - scrollOffsetBottom <= window.innerHeight)) {
        if (callback) {
            callback();
        }
        return;
    }
    let from = window.scrollY;
    let step = element.getBoundingClientRect().top;
    if (step > 0) {
        step += offset - scrollOffsetTop;
    } else {
        step -= offset - scrollOffsetBottom;
    }
    let i = 0;
    let intervalId: number;
    intervalId = setInterval(() => {
        i += 0.0125;
        // ease in out timeline
        let x = Math.sin((i - 0.5) * Math.PI) / 2 + 0.5;
        window.scroll(window.scrollX, from + (step * x));
        if (i >= 1) {
            clearInterval(intervalId);
            if (callback) {
                callback();
            }
        }
    }, 1);
}
