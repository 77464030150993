import {BigInteger} from 'biginteger';

// const ibanRegex = /DE(\d{2})(\d{8})(\d{10})/;
const ibanRegex = /^(DE|AT|CH)[0-9]{18,20}$/;

export function parseIban(iban: string): string | null {
    let result = ibanRegex.exec(iban);
    if (!result) {
        return null;
    }
    return iban;
}

