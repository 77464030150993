var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "radio-input", class: { stacked: _vm.stacked } },
    _vm._l(_vm.options, function(option, index) {
      return _c("div", { staticClass: "form-check" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model,
              expression: "model"
            }
          ],
          ref: "input",
          refInFor: true,
          staticClass: "form-check-input",
          attrs: {
            disabled: _vm.disabled,
            id: _vm.ids[index],
            name: _vm.id,
            type: "radio"
          },
          domProps: {
            value: option.value,
            checked: _vm._q(_vm.model, option.value)
          },
          on: {
            change: function($event) {
              _vm.model = option.value
            }
          }
        }),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: _vm.ids[index] },
            on: {
              click: function($event) {
                return _vm.select(option.value)
              }
            }
          },
          [_vm._v(_vm._s(option.label))]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }