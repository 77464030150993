var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "iban-input" },
    [
      _c("form-input", {
        key: "iban",
        ref: "ibanInput",
        attrs: {
          required: _vm.required,
          dataType: "iban",
          fixedPlaceholder: "",
          placeholder: "IBAN"
        },
        model: {
          value: _vm.iban,
          callback: function($$v) {
            _vm.iban = $$v
          },
          expression: "iban"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }